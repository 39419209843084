<template>
  <DesktopTable
      v-if="!isMobile"
      :items="items"
      :fields="fields"
      :expand="expand"
      :loading="loading"
      :row-class="rowClass"
      @rightClick='$emit("rightClick", $event)'>

    <!-- Slot for expanded row data -->
    <template v-slot:expand-data="props">
      <slot name="expand-data" :item="props.item"></slot>
    </template>
  </DesktopTable>

  <MobileTable
      v-else
      :items="items"
      :fields="fields"
      :expand="expand"
      :loading="loading"
      :row-class="rowClass"
      @rightClick='$emit("rightClick", $event)'>
    <template v-slot:expand-data="props">
      <slot name="expand-data" :item="props.item"></slot>
    </template>
  </MobileTable>
</template>

<script>
import DesktopTable from "@/components/table/desktop/Table";
import MobileTable from "@/components/table/mobile/Table";
import {TABLE_MOBILE_WIDTH} from "@/constants";

export default {
  name: "Table",
  components: {
    DesktopTable,
    MobileTable,
  },
  emits: [
    "rightClick",
  ],
  props: {
    items: {type: Object, required: true},
    fields: {type: Object, required: true},
    expand: {type: Boolean, required: false},
    loading: {type: Boolean, required: false},
    rowClass: {type: Function, required: false},
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth <= TABLE_MOBILE_WIDTH
    }
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth)
  },
  unmounted() {
    window.removeEventListener('resize', this.setWindowWidth)
  }
}
</script>

<style scoped>
</style>
