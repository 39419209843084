<template>
  <!-- Base table row -->
  <tr class="table-body-row">
    <!-- Expand icon column if need -->
    <td v-if="expand"
        class="table-body-row-column">
      <div
          class="table-body-row-column-cell expand"
          @click="rowExpanded = !rowExpanded">
        <i :class="expandIcon()"></i>
      </div>
    </td>

    <!-- Cells builder from fields  -->
    <template
        v-for="(field, idx) in fields"
        :key="`body_row_item_${idx}`">

      <!-- Cell builder -->
      <td class="table-body-row-column"
          @contextmenu.prevent="$emit('rightClick', item)">
        <!-- Cell icon template if exists -->
        <template v-if="field.icon">
          <i :class="field.icon(item)"></i>
          <span style="margin-left: 10px">{{ getItemValue(item, field) }}</span>
        </template>

        <!-- Cell text value with link-->
        <template v-else-if="field.link">
          <a class="table-link"
             :href="field.link(item)">
            <span>{{ getItemValue(item, field) }}</span>
          </a>
        </template>

        <!-- Cell text value -->
        <span v-else class="table-body-row-column-cell word-break">
          {{ getItemValue(item, field) }}
        </span>
      </td>
    </template>
  </tr>

  <!-- Expanded table row with data if exists -->
  <tr
      class="table-body-row"
      v-if="expand && rowExpanded">
    <td :colspan="fields.length + 1">
      <slot name="expand-data" :item="item"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "Row",
  emits: [
    "rightClick",
  ],
  props: {
    item: {type: Object, required: true},
    fields: {type: Object, required: true},
    expand: {type: Boolean, required: false},
    rowClass: {type: Function, required: false},
  },
  data() {
    return {
      rowExpanded: false,
    }
  },
  methods: {
    expandIcon() {
      return this.rowExpanded ? "el-icon-arrow-down" : "el-icon-arrow-right"
    },

    getItemValue(item, field) {
      if (!item || !field.prop) {
        return ""
      }

      field.prop.split(".").forEach((val) => {
        item = item[val]
      })

      return item
    },
  },
}
</script>

<style lang="scss" scoped>
.table-body-row {
  color: var(--sovunyaGray40);
  border-collapse: separate;
  background-color: var(--sv-theme-layout);
}

.table-body-row-column {
  position: relative;
  vertical-align: middle;
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  text-align: left;
  border-bottom: 1px solid #EBEEF5;
  transition: background-color .25s ease;
}

.table-body-row-column-cell {
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.table-link {
  color: var(--sovunyaGray40);
  font-weight: 400;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 12px 10px 0 10px;
  font-size: 14px;
}

.table-link:hover {
  color: rgba(var(--sv-primary), .8);
  text-decoration: var(--sovunyaGray40);
}

.word-break {
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}
</style>
