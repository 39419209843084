<template>
  <table class="table" border="0" cellpadding="0" cellspacing="0">
    <thead class="table-header sticky">
    <tr>
      <th v-if="expand" class="table-header-item expand sticky"/>
      <template v-for="(field, idx) in fields" :key="`head_item_${idx}`">
        <th class="table-header-item sticky">
          <div class="table-header-item-cell word-break">
            {{ field.label }}
          </div>
        </th>
      </template>
    </tr>
    </thead>

    <tbody class="table-body">

    <!--Table rows -->
    <template v-for="(item, idx) in items" :key="`body_row_${idx}`">
      <Row
          :item="item"
          :fields="fields"
          :expand="expand"
          :row-class="rowClass"
          @rightClick='$emit("rightClick", $event.id)'>

        <!-- Slot for expanded row data -->
        <template v-slot:expand-data="props">
          <slot name="expand-data" :item="props.item"></slot>
        </template>
      </Row>
    </template>

    <!-- No data row -->
    <template v-if="items.length === 0">
      <tr>
        <td :colspan="fields.length + 1">
          <div class="empty-block">
            No data
          </div>
        </td>
      </tr>
    </template>

    <!-- Loading row -->
    <template v-if="loading">
      <tr>
        <td :colspan="fields.length + 1">
          <i class="empty-block el-icon-loading loading-icon"/>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import Row from "@/components/table/desktop/Row";

export default {
  name: "Table",
  components: {
    Row,
  },
  emits: [
    "rightClick",
  ],
  props: {
    items: {type: Object, required: true},
    fields: {type: Object, required: true},
    expand: {type: Boolean, required: false},
    loading: {type: Boolean, required: false},
    rowClass: {type: Function, required: false},
  },
}
</script>

<style scoped>
.table {
  -webkit-font-smoothing: antialiased;
  background-color: var(--sv-theme-layout);
  position: relative;
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: var(--sovunyaGray40);
  border-right: 0;
  border-bottom: 0;
  padding: 20px;
  box-shadow: 0 4px 16px rgba(0, 26, 52, 0.16);
}

.sticky {
  position: sticky;
  top: 70px;
  z-index: 3;
}

.table-header {
  font-size: 14px;
  border-collapse: separate;
  font-weight: 500;
}

.table-header-item {
  vertical-align: middle;
  background-color: var(--sv-theme-layout);
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  text-align: left;
  user-select: none;
  border-bottom: 2px solid #EBEEF5;
}

.table-header-item-cell {
  color: #909399;
  white-space: normal;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.table-body {
  color: var(--sovunyaGray40);
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  table-layout: fixed;
  border-collapse: separate;
}

.expand {
  margin-left: 5px;
  width: 10px;
}

.empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  color: #909399;
}

.loading-icon {
  font-size: 32px;
}
</style>
