<template>
  <div class="sidebar-backdrop" @click="close()"></div>
  <div class="sidebar-modal">
    <div class="sidebar-modal-header">
      <div class="sidebar-modal-header-title">{{ title }}</div>

      <i class="sidebar-modal-header-icon sv-icon-close hover-less hover-background" @click="close()"/>
    </div>

    <div class="sidebar-modal-content">
      <slot name="content"></slot>
    </div>

    <div class="sidebar-modal-footer">
      <slot name="buttons"></slot>
      <SvButton @click="close()">Отмена</SvButton>
    </div>
  </div>
</template>

<script>
import {SvButton} from "@/components/default";

export default {
  name: "Update",
  components: {
    SvButton,
  },
  emits: [
    'close',
  ],
  props: {
    title: {type: String, required: true},
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  mounted() {
    document.body.style.overflow = "hidden"
  },
  unmounted() {
    document.body.style = null
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 35;
    background: rgba(0, 26, 52, 0.6);
  }

  &-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
    display: grid;
    grid-template-areas:
        "header"
        "content"
        "footer";
    grid-template-rows: auto 1fr auto;
    box-sizing: border-box;
    overflow: auto;
    background: var(--sv-theme-layout);
    height: 100%;

    &-header {
      grid-area: header;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin: 1.8rem 1.5rem 1rem 1.5rem;

      &-title {
        font-size: 1.5rem;
      }

      &-icon {
        cursor: pointer;
        width: 18px;
        height: 18px;

        &:before {
          height: 2px;
        }

        &:after {
          height: 2px;
        }
      }
    }

    &-content {
      grid-area: content;
      margin-right: 3px;
      padding: 0 21px 0 24px;
      overflow-y: auto;
    }

    &-footer {
      grid-area: footer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 24px;
      background: var(--sv-theme-layout);
      box-shadow: 0 4px 16px rgb(0 26 52 / 16%);
      flex-direction: row;
    }
  }
}

@media (min-width: 899px) {
  .sidebar-modal {
    width: 472px;
  }
}

@media (max-width: 900px) {
  .sidebar-modal {
    left: 0;
    width: 100%;
    height: 100%;
  }
}

</style>