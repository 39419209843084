<template>
  <div class="container">
    <template v-for="(item, idx) in items" :key="`card_${idx}`">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>{{ getItemValue(item, headerProp) }}</span>

            <el-button
                circle
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="clickEditButton(item.id)"/>
          </div>
        </template>

        <template v-for="(field, idx) in fields" :key="`card_field_${idx}`">
          <div v-if="!field.header" class="text item">
            <span class="card-field-label">{{ field.label }}:</span>
            <span>{{ getItemValue(item, field.prop) }}</span>
          </div>
        </template>

        <template v-if="item.expaned">
          <!-- TODO: add expanded data. -->
        </template>

        <template v-if="expand">
          <div class="expand-area" @click="item.expaned = !item.expaned">
            <i :class="item.expaned ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
        </template>
      </el-card>
    </template>
  </div>
</template>

<script>
export default {
  name: "Table",
  emits: [
    "rightClick",
  ],
  props: {
    items: {type: Object, required: true},
    fields: {type: Object, required: true},
    expand: {type: Boolean, required: false},
    loading: {type: Boolean, required: false},
    rowClass: {type: Function, required: false},
  },
  data() {
    return {
      headerProp: this.getHeaderProp()
    }
  },
  methods: {
    getHeaderProp() {
      const field = this.fields.find((val) => {
        return val.header
      })

      return field.prop || ''
    },

    clickEditButton(val) {
      this.$emit("rightClick", val)
    },

    getItemValue(item, prop) {
      if (!item || !prop) {
        return ""
      }

      prop.split(".").forEach((val) => {
        item = item[val]
      })

      return item
    },
  }
}
</script>

<style scoped>
.container {
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
  margin-bottom: 15px;
}

.card-field-label {
  font-weight: 500;
  padding-right: 5px;
}

.expand-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>